import React from "react";
import meat4 from "../images/meat4.jpeg";
import data from "../data.json";
import video from "../images/video.mp4";

function Section5({ language }) {
  return (
    <section className="u-clearfix u-palette-1-base u-section-5" id="sec-54c4">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-container-style u-layout-cell u-right-cell u-size-40 u-layout-cell-1">
                <div className="u-container-layout u-valign-middle u-container-layout-1">
                  <h3 className="u-text u-text-1">
                    {data && data[language]
                      ? data[language].sect5.welcome
                      : data.en.sect5.welcome}
                  </h3>
                  <h2 className="u-text u-text-2">
                    {data && data[language]
                      ? data[language].sect5.toOurTable
                      : data.en.sect5.toOurTable}
                  </h2>
                  <p className="u-text u-text-3">
                    {data && data[language]
                      ? data[language].sect5.text
                      : data.en.sect5.text}
                  </p>
                </div>
              </div>
              <div className="u-container-style u-expand-resize u-layout-cell u-left-cell u-size-20 u-layout-cell-2">
                <div className="u-container-layout">
                  {/* <img
                    className="u-expanded-width u-image u-image-1"
                    src={meat4}
                    alt=""
                  /> */}

                  <video
                    src={video}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="u-expanded-width u-image u-image-1"
                  ></video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section5;
