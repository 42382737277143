import React from "react";
import logo from "../images/Aghstev.png";

function Section2() {
  return (
    <section className="u-clearfix u-section-2" id="sec-703b">
      <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div className="u-layout">
          <div className="u-layout-row">
            <div className="u-align-center-md u-align-center-sm u-container-style u-layout-cell u-palette-1-base u-right-cell u-size-21 u-layout-cell-1">
              <div className="u-container-layout u-valign-middle u-container-layout-1">
                <img
                  src={logo}
                  alt=""
                  className="u-image u-image-contain u-image-default u-image-1"
                />
              </div>
            </div>
            <div className="u-container-style u-image u-layout-cell u-left-cell u-size-39 u-image-2">
              <div className="u-container-layout"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section2;
