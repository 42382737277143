import React from "react";
import drink1 from "../images/drink1.jpeg";
import drink2 from "../images/drink2.jpeg";
import data from "../data.json";

function Section7({ language }) {
  return (
    <section
      className="u-clearfix u-palette-3-base u-section-7"
      id="carousel_e4a6"
    >
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width-sm u-expanded-width-xs u-gutter-20 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-center-sm u-align-center-xs u-container-style u-layout-cell u-left-cell u-size-20 u-layout-cell-1">
                <div className="u-container-layout u-valign-middle-lg u-valign-middle-sm u-valign-middle-xl u-valign-middle-xs u-container-layout-1">
                  <h2 className="u-text u-text-1">
                    {data && data[language]
                      ? data[language].sect7.desserts
                      : data.en.sect7.desserts}{" "}
                    <br />
                    {data && data[language]
                      ? data[language].sect7.drinks
                      : data.en.sect7.drinks}
                  </h2>
                </div>
              </div>
              <div className="u-container-style u-expand-resize u-layout-cell u-size-20 u-layout-cell-2">
                <div className="u-container-layout">
                  <img
                    className="u-expanded-width u-image u-image-1"
                    src={drink1}
                    alt=""
                  />
                </div>
              </div>
              <div className="u-container-style u-expand-resize u-layout-cell u-right-cell u-size-20 u-layout-cell-3">
                <div className="u-container-layout u-valign-bottom-sm">
                  <img
                    className="u-expanded-width-lg u-expanded-width-md u-expanded-width-xl u-image u-image-2"
                    src={drink2}
                    alt=""
                  />
                  <p className="u-text u-text-2">
                    {data && data[language]
                      ? data[language].sect7.text
                      : data.en.sect7.text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section7;
