import React from "react";

function Section6() {
  return (
    <section className="u-clearfix u-image u-section-6" id="sec-df97">
      <div className="u-clearfix u-sheet u-sheet-1"></div>
    </section>
  );
}

export default Section6;
