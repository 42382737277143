import React from "react";
import logoGray from "../images/Aghstev-gray.png";
import data from "../data.json";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";

function Section9({ language }) {
  return (
    <section className="u-clearfix u-palette-2-base u-section-9" id="sec-6230">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-group u-group-1">
          <div className="u-container-layout u-valign-middle">
            <img
              src={logoGray}
              alt=""
              className="u-image u-image-contain u-image-default u-image-1"
              data-image-width="283"
              data-image-height="172"
            />
            <h2 className="u-text u-text-1">
              {data && data[language]
                ? data[language].sect9.contactUs
                : data.en.sect9.contactUs}
            </h2>
            <p className="u-text u-text-2">
              {data && data[language]
                ? data[language].sect9.addr1
                : data.en.sect9.addr1}
              <br />
              {data && data[language]
                ? data[language].sect9.addr2
                : data.en.sect9.addr2}
              <br />
              {/* {data && data[language]
                ? data[language].sect9.workingDays
                : data.en.sect9.workingDays} */}
              {/* <br /> */}
              {/* {data && data[language]
                ? data[language].sect9.dinnerHours
                : data.en.sect9.dinnerHours}
              <br />
              {data && data[language]
                ? data[language].sect9.barHours
                : data.en.sect9.barHours} */}
              {/* <br /> */}
              +374 93 88 69 39
              <br />
              +374 77 06 10 67
              <br />
              +374 33 09 09 78
              <br />
              <span>
                <a
                  href="https://www.facebook.com/profile.php?id=100068644071002"
                  target="_blank"
                >
                  <img src={facebook} alt="facebook" />
                </a>
                <a
                  href="https://instagram.com/aghstev_hangstyan_goti?igshid=YmMyMTA2M2Y="
                  target="_blank"
                >
                  <img src={instagram} alt="instagram" />
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section9;
