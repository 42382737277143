import React from "react";
import meat2 from "../images/meat2.jpeg";
import meat3 from "../images/meat3.jpeg";
// import data from "../data.json";

function Section4({ language }) {
  return (
    <section className="u-clearfix u-palette-3-base u-section-4" id="sec-8837">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width-sm u-expanded-width-xs u-gutter-20 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-container-style u-expand-resize u-layout-cell u-right-cell u-size-20 u-layout-cell-1">
                <div className="u-container-layout">
                  <img
                    className="u-expanded-width u-image u-image-1"
                    src={meat2}
                    alt=""
                  />

                  {/* <p className="u-text u-text-1">
                    {data && data[language]
                      ? data[language].sect4.text1
                      : data.en.sect4.text1}
                  </p> */}
                </div>
              </div>
              <div className="u-container-style u-expand-resize u-layout-cell u-size-20 u-layout-cell-2">
                <div className="u-container-layout">
                  <img
                    className="u-expanded-width u-image u-image-2"
                    src={meat3}
                    alt=""
                  />
                </div>
              </div>
              <div className="u-container-style u-layout-cell u-left-cell u-size-20 u-layout-cell-3">
                <div className="u-container-layout u-valign-middle-sm u-container-layout-3">
                  {/* <p className="u-text u-text-2">
                    {data && data[language]
                      ? data[language].sect4.text2
                      : data.en.sect4.text2}
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section4;
