import Aghstev from "./Aghstev";
import "./App.css";
import "./styles/styles.css";
import "./styles/nicepage.css";
import "./styles/mobile.css";
import "./styles/main-libs.css";

function App() {
  return (
    <div className="App">
      <Aghstev />
    </div>
  );
}

export default App;
