import React from "react";

function Section8() {
  return (
    <section className="u-clearfix u-section-8" id="sec-1c37">
      <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div className="u-layout">
          <div className="u-layout-col">
            <div className="u-size-30">
              <div className="u-layout-row">
                <div className="u-container-style u-image u-layout-cell u-left-cell u-size-20 u-image-1">
                  <div className="u-container-layout"></div>
                </div>
                <div className="u-container-style u-image u-layout-cell u-size-20 u-image-2">
                  <div className="u-container-layout"></div>
                </div>
                <div className="u-container-style u-image u-layout-cell u-right-cell u-size-20 u-image-3">
                  <div className="u-container-layout"></div>
                </div>
              </div>
            </div>
            <div className="u-size-30">
              <div className="u-layout-row">
                <div className="u-container-style u-image u-layout-cell u-left-cell u-size-20 u-image-4">
                  <div className="u-container-layout"></div>
                </div>
                <div className="u-container-style u-image u-layout-cell u-size-20 u-image-5">
                  <div className="u-container-layout"></div>
                </div>
                <div className="u-container-style u-image u-layout-cell u-right-cell u-size-20 u-image-6">
                  <div className="u-container-layout"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section8;
