import React, { useEffect, useState } from "react";
import Section2 from "./Sections/Section2";
import Section3 from "./Sections/Section3";
import Section4 from "./Sections/Section4";
import Section5 from "./Sections/Section5";
import Section6 from "./Sections/Section6";
import Section7 from "./Sections/Section7";
import Section8 from "./Sections/Section8";
import Section9 from "./Sections/Section9";
import Header from "./components/Header";

import { useCookies } from "react-cookie";

function Aghstev() {
  const [cookies, setCookie] = useCookies();

  const [language, setLanguage] = useState("am");

  useEffect(() => {
    setLanguage(cookies.lang);
  }, [cookies]);

  return (
    <div className="page-preview">
      <Header />
      <div className="page-preview-body">
        <Section2 />
        <Section3 language={language} />
        <Section4 language={language} />
        <Section5 language={language} />
        <Section6 />
        <Section7 language={language} />
        <Section8 />
        <Section9 language={language} />
      </div>
    </div>
  );
}

export default Aghstev;
