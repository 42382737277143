import React, { useEffect } from "react";

import armenian from "../images/armenian-flag.png";
import american from "../images/american-flag.png";
import russian from "../images/russian-flag.png";

import email from "../images/email.svg";
import phone from "../images/phone.svg";

import { useCookies } from "react-cookie";

function Header() {
  const [cookies, setCookie] = useCookies();

  return (
    <div className="header">
      <div className="phone-and-mail-container">
        <div className="phone-container">
          <img src={phone} className="phone-and-mail" alt="phone" />
          <a href="tel:+37493886939">+374 93 88 69 39</a>
          <a href="tel:+37477061067">+374 77 06 10 67</a>
          <a href="tel:+37433090989">+374 33 09 09 89</a>
        </div>
        <div className="email-contaniner">
          <img src={email} className="phone-and-mail" alt="email" />
          <a href="mailto:a.a.d.a@mail.ru">a.a.d.a@mail.ru</a>
        </div>
      </div>
      <div className="language-container">
        <img
          src={armenian}
          alt="armenian flag"
          className="flag"
          onClick={() => setCookie("lang", "am")}
        />
        <img
          src={american}
          alt="american flag"
          className="flag"
          onClick={() => setCookie("lang", "en")}
        />
        <img
          src={russian}
          alt="russian flag"
          className="flag"
          onClick={() => setCookie("lang", "ru")}
        />
      </div>
    </div>
  );
}

export default Header;
